require("@rails/ujs").start()
require("@rails/activestorage").start()
require('jquery')
require("jquery-ui/ui/widgets/autocomplete");
import "bootstrap";
import * as bootstrap from "bootstrap";
import "../stylesheets/application"
import "@hotwired/turbo-rails"
import '@fortawesome/fontawesome-free/js/all'

document.addEventListener("DOMContentLoaded", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
})
